import React, { useState } from 'react'
import "./Features.css";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import warehouse1 from "../assets/icons/inventory-management.png"
import warehouse2 from "../assets/icons/inventory-management (1).png"
import productReturn1 from "../assets/icons/product-return.png"
import productReturn2 from "../assets/icons/product-return2.png"
import b2c1 from "../assets/icons/b2c.png"
import b2c2 from "../assets/icons/b2c2.png"
import integration1 from "../assets/icons/collaborate.png"
import integration2 from "../assets/icons/collaborate2.png"
import mwarehouse1 from "../assets/icons/warehouse (1).png"
import mwarehouse2 from "../assets/icons/warehouseBlack.png"
import shippingNote1 from "../assets/icons/delivery-note.png"
import shippingNote2 from "../assets/icons/delivery-note (1).png"
import orderFul1 from "../assets/icons/order-fulfillment.png";
import orderFul2 from "../assets/icons/order-fulfillment (1).png"
import transfer1 from "../assets/icons/transfer.png"
import transfer2 from "../assets/icons/transfer (1).png"
import inout1 from "../assets/icons/distribution.png"
import inout2 from "../assets/icons/distribution (1).png"
import scanner1 from "../assets/icons/scanner.png"
import scanner2 from "../assets/icons/scanner (1).png"
import multi1 from "../assets/icons/network-connection.png"
import multi2 from "../assets/icons/network-connection (1).png"
import batch1 from "../assets/icons/paper.png"
import batch2 from "../assets/icons/paper (1).png"
import expiry1 from "../assets/icons/expiry.png"
import expiry2 from "../assets/icons/expiry (1).png"
import error1 from "../assets/icons/error.png"
import error2 from "../assets/icons/error (1).png"
import shipped1 from "../assets/icons/shipped.png"
import shipped2 from "../assets/icons/shipped (1).png"
import trade1 from "../assets/icons/trade.png"
import trade2 from "../assets/icons/trade (1).png"
import note2 from "../assets/icons/notes.png"
import note1 from "../assets/icons/notes1.png"
import tracking1 from "../assets/icons/delivery-truck.png"
import tracking2 from "../assets/icons/delivery-truck (1).png"
import cancel1 from "../assets/icons/delivery-cancelled.png"
import cancel2 from "../assets/icons/delivery-cancelled (1).png"
import cash1 from "../assets/icons/cash-on-delivery (1).png"
import cash2 from "../assets/icons/cash-on-delivery.png"
import report1 from "../assets/icons/report.png"
import report2 from "../assets/icons/report (1).png"
import invoice1 from "../assets/icons/bill.png"
import invoice2 from "../assets/icons/bill (1).png"
import validation1 from "../assets/icons/validation.png"
import validation2 from "../assets/icons/validation (1).png"


const orderFeatures = [
        {
            heading:"Order & Inventory Management",
            content:"Unified system for seamless inventory management across channels, preventing stock issues, improving fulfillment options, reducing errors, and simplifying workflows.",
            img1:warehouse1,
            img2:warehouse2
        },
        {
            heading:"Return Management",
            content:"Streamline returns: Our system handles all return cases, including non-delivered and delivered items. Create replacements or process returns, with seamless inward counting at the right warehouse location.",
            img1:productReturn2,
            img2:productReturn1
        },
        {
            heading:"B2C Fulfilment",
            content:"Easily manage B2C orders and returns in eCommerce. Seamlessly adapt to changing business dynamics.",
            img1:b2c2,
            img2:b2c1
        },
        {
            heading:"Integrations to Help You ScaleWith Ease",
            content:"Simplify operations: No toggling between systems. Get AWB, labels, tracking within Order Management System.",
            img1:integration2,
            img2:integration1
        },
        {
            heading:"Enabling Multi-warehouse Fulfillment",
            content:"Efficient warehousing: WMS optimizes location, reducing costs, and shipment numbers. Rule-based line splitting enhances delivery.",
            img1:mwarehouse2,
            img2:mwarehouse1
        },
        {
            heading:"Advanced Shipping Note Management",
            content:"Enhance inbound efficiency: Vin eRetail WMS streamlines pre-warehouse shipments with Advanced Shipping Note. Gain advanced stock visibility for optimized resource allocation.",
            img1:shippingNote1,
            img2:shippingNote2
        },
        {
            heading:"Order Fulfillment from Stores",
            content:"Streamline order fulfillment: System optimizes store selection, rules, and splitting for efficient customer delivery.",
            img1:orderFul1,
            img2:orderFul2
        },
        {
            heading:"Stock Transfers across Stocking Points (Warehouses & Stores)",
            content:"Optimize stock flow: Monitor goods from request to delivery at destination for efficient inventory management.",
            img1:transfer1,
            img2:transfer2
        },
]

const warehouseFeature = [
    {
        heading:"Smooth Inbound/Outbound Operations",
        content:"Configurable rules for smooth receiving, shipping and packaging for hassless operations.",
        img1:inout1,
        img2:inout2
    },
    {
        heading:"Accurate Barcode Scanning",
        content:"Scan and print barcodes for multiple warehouse operations without any 3rd party paid software.",
        img1:scanner1,
        img2:scanner2
    },
    {
        heading:"Multi-client, Multi-company, and Multi-location Warehousing Management",
        content:"It enables enterprises to standardize warehouse operation flows to manage multi-client inventory and users to manage the warehouse inventory through a single login in real-time.",
        img1:multi1,
        img2:multi2
    },
    {
        heading:"Batch Management",
        content:"Effective warehouse management system employs Batch & Wave picking to improve accuracy, streamline process, space optimisation & productivity.",
        img1:batch1,
        img2:batch2
    },
    {
        heading:"Expiry Management",
        content:"Efficiently manage inventory expiration with our Warehouse Management System. Track dates, receive alerts, and prioritize stock rotation for optimal operations and reduced wastage.",
        img1:expiry1,
        img2:expiry2
    },
    {
        heading:"Error Free Cycle Count",
        content:"Inventory Count & Control have a direct impact on the accuracy, speed & profitability of your warehousing operations.",
        img1:error1,
        img2:error2
    }
]

const transportFeature = [
    {
        heading:"Last mile local deliveries ",
        content:"",
        img1:shipped1,
        img2:shipped2

    },
    {
        heading:"Last mile international deliveries",
        content:"",
        img1:trade1,
        img2:trade2
    },
    {
        heading:"Automatic shipment note creation",
        content:"",
        img1:note1,
        img2:note2
    },
    {
        heading:"Parcel tracking",
        content:"",
        img1:tracking1,
        img2:tracking2
    },
    {
        heading:"Cancelations, exchanges and returns",
        content:"",
        img1:cancel1,
        img2:cancel2,
    },
    {
        heading:"COD management",
        content:"",
        img1:cash2,
        img2:cash1,
    },
    {
        heading:"Multi courier selection",
        content:"",
        img1:multi1,
        img2:multi2,
    }
]

const analyticsFeature = [
    {
        heading:"Reports",
        content:"",
        img1:report1,
        img2:report2,
    },
    {
        heading:"Invoices ",
        content:"",
        img1:invoice1,
        img2:invoice2,
    },
    {
        heading:"Validations",
        content:"",
        img1:validation1,
        img2:validation2,
    },
]

function Features() {

    const [btn, setBtn] = useState('0')
    const [featureCard, setFeatureCard] = useState(0)

    const featureBtnClicked = (e)=>{
            setBtn(e.currentTarget.id);
            setFeatureCard(0);
    }

    const featureCardClicked = (e)=>{
            setFeatureCard(e.currentTarget.id);
    }
    return (
        <div className='features'  id='feature'>
            <h1>Features</h1>
            <p>Trusted by market leaders, Fulfilment WMS is equipped with all possible and unique features of Warehouse Management Software</p>

            <div className='container-lg'>
                <div className='row mt-5'>
                    <div className='col-sm-6 col-md-6 col-lg-3  mb-3'>
                        <div  className={btn==='0'?'btn feature-btn active':'btn feature-btn'} id='0' onClick={featureBtnClicked}>Order, Inventory & Return Management System</div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-3 mb-3'>
                        <div className={btn==='1'?'btn feature-btn active':'btn feature-btn'}  id='1' onClick={featureBtnClicked}>Warehouse Management System</div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-3 mb-3'>
                        <div className={btn==='2'?'btn feature-btn active':'btn feature-btn'}   id='2' onClick={featureBtnClicked}>Transport Management System
                            </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-3 mb-3'>
                        <div className={btn==='3'?'btn feature-btn active':'btn feature-btn'}   id='3' onClick={featureBtnClicked}>Analytics & Invoices</div>
                    </div>
                </div>
            </div>

            <div className='container-lg'>
                <div className='row mt-5 justify-content-between'>
                   
            {btn==='0'&&
                   orderFeatures.map((feature, index)=>{
                    return <div className='col-md-6 mb-4'>
                        <div className={featureCard==index?'card feature-card active h-100':'card feature-card h-100'}   id={index} onClick={featureCardClicked}>
                            <div className='card-top'>
                                <div className='feature-icon-div'>
                                {featureCard==index?<img className='feature-icon2 ' src={feature.img2} alt='inventory' />:<img className='feature-icon1' src={feature.img1} alt='inventory' />}
                                </div>
                                <h5> {feature.heading} </h5>
                            </div>
                            <div className='card-bottom mt-3'>
                                <p>{feature.content}</p>
                            </div>
                        </div>
                    </div>
                   })
            }
            {btn==='1'&&
                   warehouseFeature.map((feature, index)=>{
                    return <div className='col-md-6 mb-4'>
                        <div className={featureCard==index?'card active feature-card h-100':'card feature-card h-100'}   id={index} onClick={featureCardClicked}>
                            <div className='card-top'>
                            <div className='feature-icon-div'>
                                {featureCard==index?<img className='feature-icon2 ' src={feature.img2} alt='inventory' />:<img className='feature-icon1' src={feature.img1} alt='inventory' />}
                                </div>
                                <h5> {feature.heading} </h5>
                            </div>
                            <div className='card-bottom mt-3'>
                                <p>{feature.content}</p>
                            </div>
                        </div>
                    </div>
                   })
            }

            {btn==='2'&&
                   transportFeature.map((feature, index)=>{
                    return <div className='col-md-6 mb-4'>
                        <div className={featureCard==index?'card feature-card active h-100':'card feature-card h-100'}   id={index} onClick={featureCardClicked}>
                            <div className='card-top'>
                                 <div className='feature-icon-div'>
                                {featureCard==index?<img className='feature-icon2 ' src={feature.img2} alt='inventory' />:<img className='feature-icon1' src={feature.img1} alt='inventory' />}
                                </div>
                                <h5> {feature.heading} </h5>
                            </div>
                            <div className='card-bottom mt-3'>
                                <p>{feature.content}</p>
                            </div>
                        </div>
                    </div>
                   })
            }

            {btn==='3'&&
                   analyticsFeature.map((feature, index)=>{
                    return <div className='col-md-6 mb-4'>
                        <div className={featureCard==index?'card feature-card active h-100':'card feature-card h-100'}   id={index} onClick={featureCardClicked}>
                            <div className='card-top'>
                            <div className='feature-icon-div'>
                                {featureCard==index?<img className='feature-icon2 ' src={feature.img2} alt='inventory' />:<img className='feature-icon1' src={feature.img1} alt='inventory' />}
                                </div>
                                <h5> {feature.heading} </h5>
                            </div>
                            <div className='card-bottom mt-3'>
                                <p>{feature.content}</p>
                            </div>
                        </div>
                    </div>
                   })
            }

                </div>
              
            </div>

        </div>
    )
}

export default Features
