import React, { useState } from 'react'
import "./Navbar.css"
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

function Navbar(props) {

  const [navbar, setNavbar] = useState(false);

  // const navigate = useNavigate();

  const toggleNavbar = () => {
    // console.log("clicked")
    setNavbar(!navbar);
  }
  
  const sendToHome = () => {
    const element = document.getElementById('home');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setNavbar(!navbar);
  };
  const SendToFeature = () => {
    const element = document.getElementById('feature');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setNavbar(!navbar);
  };
  const sendToCategory = () => {
    const element = document.getElementById('category');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setNavbar(!navbar);
  };
  const sendToEnquiry = () => {
    const element = document.getElementById('enquiry');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setNavbar(!navbar);
  };
  

  return (
    <nav id='home' onClick={sendToHome}>
      {/* <img id='logo' onClick={sendToHome} className='logo-img img-fluid img-responsive' src={require("../../images/codeify.png")} alt='logo' /> */}
      <p style={{color:'#fff', margin:"0px"}}>LOGO</p>
      <div className='hamburger'>
        {navbar ? <CloseIcon style={{fill:"#fff", fontSize:"200%"}} onClick={toggleNavbar} /> : <MenuIcon   style={{fill:"#fff", fontSize:"200%"}} onClick={toggleNavbar} />}
      </div>
      <ul id="nav-bar" className={navbar ? 'active' : 'act'} >
       <li>
          <p onClick={sendToHome}>Home</p>
        </li>
        <li>
          <p onClick={SendToFeature}>Feature</p>
        </li>
        <li>
          <p onClick={sendToCategory}>Categories</p>
        </li>
        <li>
          <p onClick={sendToEnquiry}>Enquiry</p>
        </li>
        <li>
          <button onClick={sendToEnquiry}  className='talk-btn btn'>REQUEST A DEMO</button>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
