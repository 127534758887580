import React from 'react'
import "./Footer.css";
import { useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

function Footer() {

    

   

  const sendToHome = () => {
    const element = document.getElementById('home');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
      const SendToFeature = () => {
        const element = document.getElementById('feature');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
      const sendToCategory = () => {
        const element = document.getElementById('category');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
      const sendToEnquiry = () => {
        const element = document.getElementById('enquiry');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      

    return ( <>
    <div className="container-fluid " style={{margin:"0px", padding:"0px"}}>

<footer
        className="text-center text-lg-start text-white"
        style={{backgroundColor:"#021639"}}

        >
  <section
           className="d-flex justify-content-between p-2 align-items-center"
           style={{backgroundColor:"#ff7a00"}}
           >
    <div className="me-5 footer-msg">
      <h4>Get connected with us on social networks:</h4>
    </div>

    <div style={{display:"flex"}}>
      <a href="https://www.facebook.com/codeifyofficial?mibextid=LQQJ4d" className="text-white me-4">
        <FacebookIcon fontSize='large'/>
      </a>
      <a href="https://instagram.com/codeifyit?igshid=MzRlODBiNWFlZA==" className="text-white me-4">
        <InstagramIcon fontSize='large'/>
      </a>
     
    </div>
  </section>

  <section className="">
    <div className="container text-center text-md-start mt-5">
      <div className="row mt-3">
        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <h3 className="text-uppercase fw-bold">FULFILMENT WMS</h3>
          <hr
              className="mb-4 mt-0 d-inline-block mx-auto"
              style={{width:"60px", backgroundColor:"#38b6ff", height:"2px"}}
              />
          <p>
          lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
          </p>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
        
        </div>

        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h4 className="text-uppercase fw-bold">Links</h4>
          <hr
              className="mb-4 mt-0 d-inline-block mx-auto"
              style={{width:"60px", backgroundColor:"#38b6ff", height:"2px"}}
              />
          <p className='links' onClick={sendToHome}> Home</p>
          <p className='links' onClick={SendToFeature}>Features</p>
          <p className='links' onClick={sendToCategory}>Categories</p>
          <p className='links' onClick={sendToEnquiry}>Enquiry</p>
        </div>

        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h4 className="text-uppercase fw-bold">Contact</h4>

          <hr
              className="mb-4 mt-0 d-inline-block mx-auto"
              style={{width:"60px", backgroundColor:"#38b6ff",height:"2px"}}
              />
          {/* <p> <LocationOnIcon fontSize='large'/> &Work Coworking Plot No. 5B, Sector 15A Neelam Chowk Ajronda Metro Near Crown Plaza, Faridabad, Haryana 121007</p> */}
          <p> <EmailIcon fontSize='large' /> fulfilmentWMS@gmail.com</p>
          <p> <LocalPhoneIcon fontSize='large' /> +91 97XXXXXXXX</p>
          <p> <LocalPhoneIcon fontSize='large' /> +91 97XXXXXXXX</p>
        </div>
      </div>
    </div>
  </section>

  <div
       className="text-center p-3"
       style={{backgroundColor:"rgba(0, 0, 0, 0.2)"}}
       >
   <p style={{margin:"0px"}}> © 2023 Copyright : Fulfilment WMS</p>
  </div>
</footer>

</div>
</>
    )
}

export default Footer
