import React from 'react'
import "./Categories.css";
import ChairIcon from '@mui/icons-material/Chair';
import DiamondIcon from '@mui/icons-material/Diamond';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import home1 from "../assets/icons/household-appliance.png"
import home2 from "../assets/icons/household-appliance (1).png"
import fashion1 from "../assets/icons/fashion.png"
import fashion2 from "../assets/icons/fashion (1).png"
import b2b1 from "../assets/icons/b2b.png"
import b2b2 from "../assets/icons/b2b (1).png"
import shop1 from "../assets/icons/shopping-cart.png"
import shop2 from "../assets/icons/shopping-cart (1).png"
import elec1 from "../assets/icons/responsive.png"
import elec2 from "../assets/icons/responsive (1).png"
import fitness1 from "../assets/icons/dumbbell.png"
import fitness2 from "../assets/icons/dumbbell (1).png"
import fmcg1 from "../assets/icons/take-away.png"
import fmcg2 from "../assets/icons/take-away (1).png"
import health1 from "../assets/icons/healthcare.png"
import health2 from "../assets/icons/healthcare (1).png"


const categories = [
    {
        heading:"Home Appliances",
        img1:home1,
        img2:home2
        
    },
    {
        heading:"Fashion & LifeStyle",
        img1:fashion1,
        img2:fashion2
        
    },
    {
        heading:"Industrial B2B",
        img1:b2b1,
        img2:b2b2
        
    },
    {
        heading:"Quick Commerce",
        img1:shop1,
        img2:shop2
        
    },
    {
        heading:"Electronics",
        img1:elec1,
        img2:elec2
        
    },
    {
        heading:"Fitness",
        img1:fitness1,
        img2:fitness2
        
    },
    {
        heading:"FMCG",
        img1:fmcg1,
        img2:fmcg2
        
    },
    {
        heading:"Health & Beauty",
        img1:health1,
        img2:health2
        
    },
    {
        heading:"Electronics",
        img1:elec1,
        img2:elec2
        
    },
    {
        heading:"Fitness",
        img1:fitness1,
        img2:fitness2
        
    },
    {
        heading:"FMCG",
        img1:fmcg1,
        img2:fmcg2
        
    },
    {
        heading:"Health & Beauty",
        img1:health1,
        img2:health2
        
    },
   
   
]

function Categories() {
  return (
    <div className='categories' id='category'>
            <h1>Categories We Are<span> Serving</span></h1>

            <div className='container-lg'>
                <div className='row mt-5'>
                {
                    categories.map((category, index)=>{
                return <div className='col-6 col-sm-3 col-md-2  mb-4 '>
                        <div className='card category-card h-100'  onMouseOver={e => (e.currentTarget.firstChild.firstChild.src = category.img2)}   onMouseOut={e => (e.currentTarget.firstChild.firstChild.src = category.img1)}>
                            <div className='category-icon'>
                                <img  src={category.img1}
                                

        alt='home'/>
                            </div>
                            <p>{category.heading}</p>
                        </div>
                </div>

                    })
                }
       
                </div>
            </div>
    </div>
  )
}

export default Categories
