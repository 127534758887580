import React from 'react'
import "./Hero.css";

function Hero() {
  return (
    <div className='hero'>
                <h1>Fulfilment WMS for B2B, B2C and D2C</h1>
<h5>Available with Source code & Annual Maintenance</h5>
    </div>
  )
}

export default Hero
