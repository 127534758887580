import './App.css';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Services from './components/Services';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Features from './components/Features';
import Extended from './components/Extended';
import Categories from './components/Categories';
import Stats from './components/Stats';
import Enquiry from './components/Enquiry';
import Footer from './components/Footer';
// import ScrollToTop from "react-scroll-to-top";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

function App() {
  const handleClickScroll = () => {
    const element = document.getElementById('feature');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
 

  return (
    <div className="App">
     {/* <ScrollToTop /> */}
     <FloatingWhatsApp  phoneNumber="9717570933" accountName="Fulfilment WMS"  allowClickAway={true}/>
      <Navbar />
      <Hero />
      <Services />
      <Features />
      <Extended />
      <Categories />
      <Stats />
      <Enquiry />
      <Footer />
    </div>
  );
}

export default App;
