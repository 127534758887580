import React from 'react'
import "./Stats.css";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

function Stats() {
  return (
    <div className='stats'>
            <div className='container'>
                <h2>Fulfilment WMS makes a fast impact on your business</h2>
                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                {({ isVisible }) => (
                    <>
                <div className='row mt-5'>
                    <div className='col-lg-4'>
                        <div className='card stat-card'>
                            <h2>{isVisible ? <CountUp enableScrollSpy={true} scrollSpyDelay={1000}   duration={3} end={25}   />: '25'}%</h2>
                            <p>increase in labor efficiency</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='card stat-card'>
                            <h2>{isVisible ? <CountUp enableScrollSpy={true} scrollSpyDelay={1000}   duration={3} end={3}   />: '3'}x</h2>
                            <p>more orders shipped after first
three months on Fulfilment wms</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='card stat-card'>
                            <h2>{isVisible ? <CountUp enableScrollSpy={true} scrollSpyDelay={1000}   duration={3} end={8}   />: '8'}%</h2>
                            <p>lower shipping costs with Logiwa
rate shopping</p>
                        </div>
                    </div>
                </div>
                </>
                    )}
                </VisibilitySensor>
            </div>
    </div>
  )
}

export default Stats
