import React from 'react'
import "./Services.css"
import clientData from "../assets/lottie/eAI2JaS4um.json"
import Lottie from 'lottie-react'

function Services() {
    return (
        <div className='services-bg'>
            <div className='services container-lg'>
                <div className='row justify-content-around d-flex flex-column-reverse flex-sm-column-reverse flex-md-row'>
                    <div className='col-sm-12 col-md-8 service-left'>
                        <div className='row py-2'>
                            <div className='col-sm-6 mb-4'>
                                <div className='service-div'>
                                    <div className='circle'>
                                        <img src={require("../assets/icons/warehouseBlack.png")} alt='warehouse' />
                                    </div>
                                    <div className='content'>
                                        <h4>3PL Warehouse</h4>
                                        <p> Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                            sed do eiusmod tempor</p>
                                    </div>
                                </div>

                            </div>
                            <div className='col-sm-6 mb-4'>
                                <div className='service-div'>
                                    <div className='circle'>
                                        <img src={require("../assets/icons/fulfillment.png")} alt='warehouse' />
                                    </div>
                                    <div className='content'>
                                        <h4>Fullfilment Center</h4>
                                        <p> Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                            sed do eiusmod tempor</p>
                                    </div>
                                </div>

                            </div>
                            <div className='col-sm-6 mb-4'>
                                <div className='service-div'>
                                    <div className='circle'>
                                        <img src={require("../assets/icons/fulfillment2.png")} alt='warehouse' />
                                    </div>
                                    <div className='content'>
                                        <h4>Micro Fulfillment Center</h4>
                                        <p> Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                            sed do eiusmod tempor</p>
                                    </div>
                                </div>

                            </div>
                            <div className='col-sm-6 mb-4'>
                                <div className='service-div'>
                                <div className='circle'>
                                        <img src={require("../assets/icons/dark.png")} alt='warehouse'/>
                                    </div>
                                    <div className='content'>
                                        <h4>Dark Stores</h4>
                                        <p> Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                            sed do eiusmod tempor</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 mb-4'>
                                <div className='service-div'>
                                <div className='circle'>
                                        <img src={require("../assets/icons/moving-truck.png")} alt='warehouse'/>
                                    </div>
                                    <div className='content'>
                                        <h4>Distributer</h4>
                                        <p> Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                            sed do eiusmod tempor</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6 mb-4'>
                                <div className='service-div'>
                                <div className='circle'>
                                        <img src={require("../assets/icons/seller.png")} alt='warehouse'/>
                                    </div>
                                    <div className='content'>
                                        <h4>Brands & Online Seller</h4>
                                        <p> Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit,
                                            sed do eiusmod tempor</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-4 service-right'>
                        <h2>Target Clients</h2>
                        <h5>Fulfilment WMS is trusted by leading businesses
                            across the globe with features suited.</h5>
                        <div className='lottie-div'>
                            <div className='client'>
                                <Lottie animationData={clientData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
