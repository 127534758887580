import React from 'react'
import "./Extended.css";

function Extended() {
    return (
        <div className='extended'>
            <h1>Extended <span>Features</span></h1>
            <p>Get extended Warehouse management software features with Fulfilment WMS 
            </p>

            <div className='container'>
                <div className='row mt-5 h-100'>
                    <div className='col-md-6 mb-5'>
                        <div className='card h-100 extended-card'>
                            <h3>Repacking</h3>
                            <p>Manage customer requirement driven repacking
                                service in any quantities, even if this is just changing
                                from one box to another size of the box.</p>
                        </div>
                    </div>
                    <div className='col-md-6 mb-5'>
                        <div className='card h-100 extended-card'>
                            <h3>Grading</h3>
                            <p>Sort and categorize the bulk-purchased items into
                                SKUs.
                            </p>
                        </div>
                    </div>
                    <div className='col-md-6 mb-5'>
                        <div className='card h-100 extended-card'>
                            <h3>Reverse Logistics</h3>
                            <p>Both Vendor & Customer returns are available in the
                                Warehouse management software. This feature helps
                                to mitigate the returns.</p>
                        </div>
                    </div>
                    <div className='col-md-6 mb-5'>
                        <div className='card h-100 extended-card'>
                            <h3>Order & Shipment Management</h3>
                            <p>Warehouse Management System can be packaged
                                with Distributed Order Management, Transport
                                Management System & Supply Chain Planning.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Extended
